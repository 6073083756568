import React from "react"
import Layout from "../components/layout"
import {} from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"

const SmileTermsConditionsPage = () => {
  let language = "en"
  let county = "Middlesex County, New Jersey"
  let county_caps = county.toUpperCase()
  let event_name = "Lip Balm"
  let sponsor = "Oral Surgery Group"
  let email = "jaclyn@oralsurgerygroup.com"
  let hashtag = "#MyOSGSmile"
  let r1_point =
    "Applicant’s image post on social media showing the branded lip balm anywhere in the world and correctly using the Contest hashtag #MyOSGSmile"
  let file_action_at =
    "The United States District Court for the District of New Jersey, the appropriate New Jersey State Court located in Middlesex County, New Jersey, or the appropriate county court located in Middlesex County, New Jersey;"
  let commonwealth_of = "New Jersey"
  let paragraph_12 =
    "This Contest is not in any manner sponsored, endorsed, administered by or associated with Meta, Google, or other social platforms. Each Applicant understands that they are providing their information to Sponsor and their designees only. The information an Applicant provides will only be used as stated in these Official Terms & Conditions. By participating in the Contest, each Applicant releases and agrees to indemnify Meta, Google, or any other social platforms and hold them harmless from and against any and all costs, claims, damages (including, without limitation, any special, incidental or consequential damages) or any other injury, whether due to negligence or otherwise, to person(s) or property (including, without limitation, death or violation of any personal rights, such as violation of right of publicity/privacy, libel or slander), due in whole or in part, directly or indirectly, to participation in the Contest."
  let mail_to = "109 Livingston Ave, New Brunswick, NJ 08901"

  return (
    <Layout pageTitle="procedure-page" language={language}>
      <SEO
        title={`Terms & Conditions for the ${event_name} Program`}
        description={`Please fully review the terms & conditions of the ${event_name} program prior to submitting of your application.`}
      />
      {/* <Button 
          buttonText={language === "es" ? "ATRÁS" : "BACK"}
          goBack
          /> */}

      <div className="joshua-tree-content program-page-wisdom">
        <div className="columns top-section white-back has-text-centered">
          <div className="column">
            <h1>{event_name} Application</h1>
          </div>
        </div>

        <section className="body-section">
          <div className="columns">
            <div className="column is-6"></div>
            <div className="column">
              <div className="event-container single-column-event">
                <div style={{ textAlign: "center", fontWeight: "bold" }}>
                  <p className="p1">
                    <b>OFFICIAL TERMS &amp; CONDITIONS</b>
                  </p>
                  <p className="p3">
                    <b>
                      NO PURCHASE OR PAYMENT OF ANY KIND IS NECESSARY. A
                      PURCHASE OR PAYMENT WILL NOT INCREASE YOUR CHANCES OF
                      BEING SELECTED.
                    </b>
                  </p>
                  <p className="p4">
                    OPEN ONLY TO ELIGIBLE LEGAL RESIDENTS OF THE UNITED STATES
                    OF AMERICA WHO RESIDE IN OR NEAR {county_caps}, AND WHO ARE
                    AT LEAST 18 YEARS OLD AS OF THE DATE OF APPLYING, OR BY A
                    PARENT APPLYING ON BEHALF OF THEIR CHILD. THIS CONTEST IS
                    VOID WHERE PROHIBITED. APPLICANTS MUST BE STUDENTS, OR
                    PARENTS OF STUDENTS, WHO ARE ATTENDING ELEMENTARY SCHOOL,
                    JUNIOR HIGH SCHOOL, OR HIGH SCHOOL IN THE NEXT 12 MONTHS.
                  </p>
                  <p className="p4">
                    READ THESE OFFICIAL TERMS &amp; CONDITIONS CAREFULLY AS YOU
                    WILL BE BOUND BY THEM.
                  </p>
                  <br />
                </div>
                <p className="p7">
                  <b>
                    1.<span className="Apple-converted-space"> </span>
                    ELIGIBILITY:
                  </b>{" "}
                  The “{event_name}” (“Contest”) is open only to legal residents
                  of the United States of America, who reside in or near{" "}
                  {county}, and who are at least eighteen (18) years old as of
                  the date of application, or by a parent applying on behalf of
                  their child. The contest is void where prohibited. Applicants
                  must be patients over the age of 18 who have obtained a
                  branded lip balm stick following treatment at {sponsor}. In
                  order to maintain eligibility throughout the Contest, an
                  Applicant must abide by these Official Terms &amp; Conditions
                  and maintain appropriate decorum befitting the reputation and
                  values of Sponsor and must not otherwise engage in any conduct
                  which would embarrass Sponsor or bring Sponsor into public
                  disrepute or ridicule, or which would insult or offend the
                  public or any segment thereof.
                </p>

                <p className="p4">
                  <b>
                    2.<span className="Apple-converted-space">  </span>HOW TO
                    APPLY:
                  </b>{" "}
                  During the submission period each quarter, as detailed on the
                  Sponsor website or via social media posts, an Applicant must
                  follow the instructions to apply by uploading a photo to
                  social media with the branded Sponsor lip balm and using the
                  hashtag {hashtag}. The post must be public and viewable by
                  Sponsor. If the Applicant has a private account, the Applicant
                  can upload the post to the private account, screenshot the
                  post, and email it to Sponsor at{" "}
                  <a href={`mailto:${email}`}>{email}</a>. The post must follow
                  the community guidelines of the chosen social media platform
                  and cannot contain nudity, threats of harm, hate speech, or
                  images depicting graphic violence.
                </p>
                <p className="p7">
                  <b>Limit: </b>Only one (1) Submission per Applicant per
                  quarter during the Contest. Submissions posted from any person
                  in excess of the stated limit will be void.
                </p>
                <p className="p7">
                  <b>NOTE: </b>Once a Submission has been posted, it must remain
                  online throughout the duration of the submission period.
                </p>
                <p className="p7">
                  <b>
                    3.<span className="Apple-converted-space">  </span>
                    SUBMISSION REQUIREMENTS:
                  </b>{" "}
                  In order to be eligible to participate in the Contest, an
                  Applicant must submit a submission consisting of a response
                  that complies with the requirements stated below:
                </p>
                <p className="p7">
                  An Applicant must post an image to the social media platform
                  of choice that contains the branded lip balm and properly uses
                  the Contest hashtag {hashtag}.
                </p>
                <p className="p7">
                  <b>(A) Additional Applicable Submission Requirements:</b>
                </p>
                <p className="p7">The Submission, in its entirety:</p>

                <ol
                  className="ul1"
                  type="i"
                  style={{ listStyleType: "lower-roman", padding: "0 75px" }}
                >
                  <li className="li7">
                    Must be the Applicant’s original creation (an original
                    creation is the product of one’s own mind, is not a copy or
                    imitation, and is not the result of trade secret
                    misappropriation);
                  </li>
                  <li className="li7">Must be entirely in English;</li>
                  <li className="li7">
                    Must not include hyperlinks to content on other sites or
                    media;
                  </li>
                  <li className="li7">
                    Must not have been previously submitted in any Contest or
                    promotion or published in any media;
                  </li>
                  <li className="li7">
                    Must not contain content that is unlawful, in violation of,
                    or contrary to federal, state, or local laws;
                  </li>
                  <li className="li7">
                    Should not contain material that, either explicitly or
                    implicitly, mentions, depicts, shows, refers, or otherwise
                    alludes to the name or logo, trademark, or service mark of
                    any third party, individual, company, entity, product, or
                    brand other than Sponsor’s.
                  </li>
                  <li className="li7">
                    Must not contain any material or references that violate or
                    infringe upon a third party’s rights, including but not
                    limited to privacy, publicity, or intellectual property
                    rights, nor may it in any way infringe upon a third party’s
                    copyright or trademark rights;
                  </li>
                  <li className="li7">
                    Must be in good taste as determined by Sponsor in their sole
                    discretion;
                  </li>
                  <li className="li7">
                    Must not contain language or contents that in any way
                    disparages or defames or in any way reflects negatively upon
                    the Sponsor, their practice or products or any person or
                    entity as determined by Sponsor in their sole discretion;
                  </li>
                  <li className="li7">
                    Must not contain content that is inconsistent with Sponsor’s
                    marketing practices;
                  </li>
                  <li className="li7">
                    Must not contain, mention, refer, or otherwise allude to any
                    obscene, lewd, violent, illegal, offensive, hateful,
                    disparaging, or inappropriate words, language, symbols,
                    signs, or abbreviations;
                  </li>
                  <li className="li7">
                    Must not advocate or denigrate any political or societal
                    beliefs;
                  </li>
                  <li className="li7">
                    Must not contain, mention, refer, or otherwise allude to any
                    political or religious references, statements, or symbols;
                  </li>
                  <li className="li7">
                    Must not contain words or symbols that may be considered
                    offensive to individuals of a certain race, ethnicity,
                    religion, sexual orientation, age, or socioeconomic group;
                    and
                  </li>
                  <li className="li7">
                    Must not in any way promote alcohol, illegal drugs, or
                    tobacco (or the use of any of the preceding).
                  </li>
                </ol>
                <p className="p7">
                  <b>NOTE:</b> Applicants understand and acknowledge that
                  Sponsor, in their sole discretion, after its good faith
                  consideration and review of a Submission in its totality,
                  reserve the right to accept or reject a Submission.
                  Additionally, Sponsor reserves the right at any time during
                  the Contest and thereafter to disqualify any Submission that
                  Sponsor determines, in their sole discretion, does not comply
                  with these Official Terms &amp; Conditions or otherwise
                  contains, or uses, prohibited or inappropriate content, and
                  the Applicant may also be disqualified from participation in
                  the Contest.
                </p>
                <p className="p7">
                  <b>
                    4.<span className="Apple-converted-space">  </span>CONTEST
                    EVALUATION, SELECTION &amp; VERIFICATION PROCESS:
                  </b>{" "}
                  Only Submissions from eligible Applicants as defined in
                  Section 1 above whose Submissions are in compliance with
                  Section 3 will be included for selection as stated herein. The
                  Contest will consist of one (1) round of evaluation every
                  quarter. Evaluators may be referred to herein collectively as
                  “Contest Evaluators.”
                </p>
                <p className="p7">
                  <b>(A) ROUND ONE EVALUATION PERIOD: </b>All eligible Entries
                  received by Sponsor by the posted submission deadline be
                  evaluated and assigned points by a panel of Contest Evaluators
                  comprised of Sponsor’s designated administrative, marketing,
                  and professional experts in accordance with the following
                  criteria:
                </p>
                <p className="p7">
                  Responses will be judged, evaluated, and assigned a number of
                  points based on the following:
                </p>

                <ol
                  className="ul1"
                  style={{ listStyleType: "lower-alpha", padding: "0 75px" }}
                >
                  <li className="li7">{r1_point}</li>
                </ol>
                <p className="p10">
                  Evaluation will take place at approximately the end of every
                  quarter in the Spring, Summer, Fall, and Winter.
                </p>
                <p className="p10">
                  <b>(B) SELECTION OF THE RECIPIENT OF THE DENTAL PROCEDURE:</b>{" "}
                  On or about the end of every quarter or the beginning of the
                  next quarter, the Sponsor and Contest Evaluators will select
                  the recipient from the qualifying submissions.
                </p>
                <p className="p10">
                  The name of the potential Recipient will be determined on or
                  about the beginning of the next quarter following the
                  submission period and Sponsor will notify the potential
                  Recipient either in person, by email, and/or by telephone at
                  such time. The decisions of the Contest Evaluators and Sponsor
                  shall be final and binding in all matters pertaining to the
                  evaluation and selection process. Potential recipients
                  acknowledge that they will be the subject of a background
                  check by Sponsor or their representative so that their
                  selection as the Recipient will not embarrass or in any way
                  reflect negatively on Sponsor or the Contest and that they
                  agree to the performance of said background check.
                </p>

                <p className="p7">
                  <b>5. RESPONSIBILITIES OF ENTRANT:</b>
                </p>
                <p className="p7">
                  <b>(A)</b>{" "}
                  <b>
                    REPRESENTATIONS/WARRANTIES AS CONDITIONS OF
                    ENTRY/PARTICIPATION IN CONTEST:
                  </b>{" "}
                  By participating in this Contest, an Applicant represents and
                  warrants that: (i) she/he is of the legal capacity to
                  participate in this Contest; (ii) she/he owns all rights and
                  title to his/her Submission and the Submission is solely
                  his/her own original work; (iii) the Submission does not
                  infringe the intellectual property, privacy, publicity rights,
                  or any other legal or moral rights of any third party.
                  Additionally, an Applicant represents and warrants that
                  throughout his/her participation in this Contest, she/he will
                  conduct himself/herself in an appropriate manner and not
                  engage in any behavior or manner that would embarrass Sponsor
                  or Contest Entities, or reflect negatively on Sponsor’s
                  practice, service or products.
                </p>
                <p className="p7">
                  <b>(B) GRANTS/WAIVERS:</b> By submitting a Submission, an
                  Applicant assigns to Sponsor, their affiliates, legal
                  representatives, assistants, agents, and licensees an
                  irrevocable, exclusive, unlimited, unconditional,
                  royalty-free, fully paid-up and perpetual license to copy,
                  transmit, publish, post, broadcast, display, adapt, exhibit,
                  reproduce, encode, store, and/or otherwise use, reuse, or not
                  use his or her Submission or any portion of the Submission, as
                  submitted or as edited/modified in any way by the said
                  licensees, in their sole discretion in the Contest in any and
                  all media without limitation, worldwide and throughout
                  perpetuity, without additional compensation, notice to or
                  approval from the Applicant. Applicant understands and agrees
                  that although she/he has granted the above-stated licensee to
                  use or reuse his Submission or any portion thereof as stated
                  hereinabove, Sponsor in its sole discretion may elect not to
                  so use said Submission. THE APPLICANT REPRESENTS, UNDERSTANDS,
                  AND ACKNOWLEDGES THAT SHE/HE WILL NOT BE PAID FOR OR RECEIVE
                  ANY FORM OF COMPENSATION OR ROYALTY IN EXCHANGE FOR GRANTING
                  SPONSOR THESE RIGHTS OR FOR ANY SUBSEQUENT USE OF SUCH
                  SUBMISSION OR ANY PORTION THEREOF BY SPONSOR OTHER THAN ANY
                  SCHOLARSHIP SHE/HE MAY RECEIVE HEREIN. The Applicant waives
                  all intellectual property rights, privacy/publicity rights, or
                  other legal or moral rights that might limit or preclude
                  Sponsor’s use of the Submission or any portion thereof or use
                  of any recordings, photos or videos taken during the
                  interviews, or follow-up interviews and appointments and
                  agrees not to sue or assert any claim against Contest Entities
                  arising out of or connected to the use of any of the
                  aforementioned or any portion thereof.
                </p>
                <p className="p7">
                  <b>(C) INDEMNIFICATION/RELEASE:</b> By submitting a
                  Submission, the Applicant agrees to indemnify and hold
                  harmless the Contest Entities from any and all claims,
                  damages, expenses, costs (including reasonable attorneys'
                  fees) and liabilities (including settlements) brought or
                  asserted by any third party against any of them due to, or
                  arising out of, the Submission or his/her conduct in creating
                  the Submission or other actions in connection with this
                  Contest, including, but not limited to, claims for trademark
                  infringement, copyright infringement, work product, violation
                  of an individual’s right of publicity or right of privacy, or
                  defamation. The Applicant further agrees to release and hold
                  harmless the Contest Entities from any and all claims that any
                  advertising subsequently produced, presented, and/or prepared
                  by or on behalf of Sponsor infringes his/her rights with
                  regard to the Submission and/or any portion thereof.
                </p>
                <p className="p7">
                  <b>6. INTERNET/LIMITATIONS OF LIABILITY:</b> No liability or
                  responsibility is assumed by Sponsor or Contest Entities
                  resulting from an individual’s participation in or attempt to
                  participate in the Contest. The Contest Entities are not
                  responsible for interrupted or unavailable network server or
                  other connections; for miscommunications; failed telephone or
                  computer transmissions; for jumbled, scrambled, or misdirected
                  entries or transmissions; for phone, electrical, network,
                  computer hardware or software or program malfunctions,
                  failures or difficulties; for other errors, omissions,
                  interruptions or deletions of any kind, whether human,
                  mechanical or electronic; or for any damage to any person's
                  computer related to participating in the Contest. The Contest
                  Entities are not responsible for incorrect or inaccurate
                  information, whether caused by Internet users or by any of the
                  equipment or programming associated with or utilized in the
                  Contest; or for any technical or human errors that may occur
                  in the processing of any information in the Contest. Persons
                  found tampering with or abusing any aspect of the Contest, as
                  solely determined by Sponsor, may be disqualified and may be
                  subject to prosecution. Any person attempting to apply using
                  multiple email addresses, multiple identities, or any other
                  device or artifice to apply multiple times or to interfere
                  with the proper play of the Contest may be disqualified from
                  participation in the Contest. Additionally, any use of
                  robotic, automatic, macro, programmed, third-party or like
                  methods to participate in the Contest will void any attempted
                  participation affected by such methods and may lead to the
                  disqualification of the individual utilizing the same. Sponsor
                  reserves the right to disqualify any individual found, in its
                  sole opinion, to be tampering with the operation of the
                  Contest, to be acting in violation of these Official Terms
                  &amp; Conditions or to be acting with the intent to disrupt
                  the intended operation of the Contest. If Sponsor, in their
                  sole discretion, determines that the Contest is not or may not
                  be capable of running as intended because of viruses, bugs,
                  non-authorized human intervention, an insufficient number of
                  Applicants, Semi-Finalists or the Recipient not being in
                  compliance with these Official Terms &amp; Conditions, or any
                  other causes that corrupt or interfere or may corrupt or
                  interfere with the administration, security, fairness or
                  proper play and/or intended execution of the Contest, Sponsor
                  reserves the right, in its sole discretion, to modify,
                  suspend, cancel, or terminate the Contest and proceed in a
                  manner deemed fair and appropriate by Sponsor.
                </p>
                <p className="p7">
                  <b>
                    CAUTION: ANY ATTEMPT TO DELIBERATELY DAMAGE THE WEBSITE OR
                    ANY RELATED WEBSITE OR UNDERMINE TO THE LEGITIMATE OPERATION
                    OF THE CONTEST IS A VIOLATION OF CRIMINAL AND CIVIL LAWS.
                    SHOULD SUCH AN ATTEMPT BE MADE, SPONSOR RESERVE THE RIGHT TO
                    SEEK DAMAGES OR OTHER REMEDIES FROM ANY SUCH PERSON(S)
                    RESPONSIBLE FOR THE ATTEMPT TO THE FULLEST EXTENT PERMITTED
                    BY LAW.
                  </b>
                </p>
                <p className="p7">
                  <b>7. PRIVACY POLICY: </b>Any personally identifiable
                  information collected during an Applicant’s participation in
                  this Contest will be collected by Sponsor or their
                  representative and used by Sponsor for purposes of the proper
                  administration and fulfillment of the Contest as described in
                  these Official Terms &amp; Conditions and in accordance with
                  the requirements of the Health Insurance Portability and
                  Accountability Act.
                </p>
                <p className="p7">
                  <b>8. PUBLICITY RIGHTS: </b>By participating in this Contest,
                  an Applicant agrees to allow Sponsor’s designees the perpetual
                  right to use his/her name, biographical information, photos
                  and/or likeness, videos, and statements for Contest, trade,
                  commercial, advertising and publicity purposes, at any time or
                  times, in all media now known or hereafter discovered,
                  worldwide, including, but not limited to, on the World Wide
                  Web and Internet, without notice, review, or approval and
                  without additional compensation except where prohibited by
                  law.
                </p>
                <p className="p7">
                  <b>9. RELEASE: </b>By participating in this Contest, each
                  Applicant releases and agrees to indemnify and hold harmless
                  Contest Entities from and against any and all costs, claims,
                  damages (including without limitation, any special, incidental
                  or consequential damages) or any other injury, whether due to
                  negligence or otherwise, to person(s) or property (including,
                  without limitation, death or violation of any personal rights,
                  such as violation of right of publicity/privacy, libel, or
                  slander), due in whole or in part, directly or indirectly, to
                  participation in the Contest.
                </p>
                <p className="p12">
                  <b>10. DISPUTE RESOLUTION: </b>Except where prohibited, by
                  participating in the Contest, an Applicant agrees that: (a)
                  except as otherwise specifically set forth in these Official
                  Terms &amp; Conditions, any action arising out of or relating
                  to these Official Terms &amp; Conditions or the rights and
                  obligations of any Applicant and/or Sponsor shall be filed
                  exclusively in {file_action_at} and Applicant hereby consents
                  and submits to the personal jurisdiction of such court for the
                  purposes of litigating any such action, and any right to a
                  trial by jury is hereby waived; (b) any and all disputes,
                  claims and causes of action arising out of or connected with
                  these Official Terms &amp; Conditions, and/or the rights and
                  obligations of any Applicant and/or Sponsor, shall be resolved
                  individually, without resort to any form of class action; (c)
                  any and all claims, judgments, and awards shall be limited to
                  actual out-of-pocket costs incurred and in no event will
                  Applicant be entitled to receive attorneys’ fees or other
                  legal costs; (d) under no circumstances will Applicant be
                  permitted to obtain injunctive or other equitable relief and
                  Applicant’s sole remedy will be an action at law for damages
                  to the extent allowed by sub-paragraphs (c) and (e) of this
                  Section 11; and (e) under no circumstances will Applicant be
                  permitted, and Applicant hereby waives all rights, to: (i)
                  claim punitive, exemplary, special, incidental, indirect and
                  consequential damages and any other damages (whether due to
                  negligence or otherwise), other than for actual out-of-pocket
                  costs described in (c) above; and (ii) have damages multiplied
                  or otherwise increased. All issues and questions concerning
                  the construction, validity, interpretation and enforceability
                  of these Official Terms &amp; Conditions, or the rights and
                  obligations of any Applicant and/or Sponsor, shall be governed
                  by and construed in accordance with the laws of the
                  Commonwealth of {commonwealth_of} without giving effect or
                  regard to any principles or doctrines of conflicts of law of
                  the Commonwealth of {commonwealth_of} or any other
                  jurisdiction. If any provision or provisions of these Official
                  Terms &amp; Conditions shall be held to be invalid, illegal,
                  unenforceable or in conflict with the law of any jurisdiction,
                  the validity, legality, and enforceability of the remaining
                  provisions shall not in any way be affected or impaired
                  thereby.
                </p>
                <p className="p7">
                  <b>11. GENERAL: </b>Each Applicant waives any right to claim
                  ambiguity in these Official Terms &amp; Conditions. In the
                  event of a dispute as to the identity of a selected
                  participant based on an email address, the selected
                  participant entry will be declared made by the person
                  identified in the registration upon applying. All federal,
                  state and local laws apply. The Contest is void in
                  jurisdictions other than those stated above and wherever
                  prohibited.
                </p>
                <p className="p7">
                  <b>12. IMPORTANT: </b>
                  {paragraph_12}
                </p>
                <p className="p7">
                  <b>
                    13.<span className="Apple-converted-space">  </span>NAME OF
                    RECIPIENT:{" "}
                  </b>
                  To obtain the name of the Recipient, send a properly stamped
                  self-addressed envelope, postmarked to {event_name} Recipient
                  List, {mail_to}.
                </p>
                <p className="p7">
                  <b>Sponsor: </b>
                  {sponsor}
                </p>
              </div>
            </div>
            <div className="column is-6"></div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default SmileTermsConditionsPage
